// colors
$main-bg: #f0f2f5;
$dark-cyan: #4689FF;
$moderate-cyan: #37abb9;
$grey-boarder: #e1e1e1;
$white: #ffffff;
$dark-grey: #4e4e4e;
$light-grey: #a1a1a1;
$lightest-grey: #f2f2f2;

// fonts
$font-family-base: "Roboto, Regular", sans-serif;

.container {
  position: relative;
  height: 100%;
  font-size: 0.8em;
  padding: 2rem;
  background-color: $white;
}
.filterHeading {
  display: block;
  margin-bottom: 5px;
  position: relative;
  color: #999999;
  white-space: nowrap;
}
.networkContainer {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}
.networkLeftLine {
  position: absolute;
  right: 100%;
  width: 2px;
  height: 130%;
  transform: translateX(-6px);
}
.statusContainer {
  width: fit-content;
  box-sizing: border-box;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: flex-start;
  padding: 3px 8px;
  border-radius: 6px;
  margin: auto auto auto 0;
}
.statusCircle {
  width: 6px;
  height: 6px;
  border-radius: 50%;
  margin: auto 8px auto 0;
}
.statusText {
  display: block;
  display: flex;
  width: fit-content;
  white-space: nowrap;
  margin: 0;
  padding: 0;
  font-size: 12px;
  align-items: center;
  justify-content: center;
  flex-flow: row nowrap;
}
.actionsContainer {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  width: 100%;
}
.closed {
  display: none;
}
